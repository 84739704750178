<template>
  <page-loader :loading="fetching">
    <div class="container pt-4">
      <order-details-modal
        :visible="order != null"
        :order="order"
        :show-loader="updating"
        @cancel="handleOrderDetailsModalHidden"
        @accepted="handleOrderAccept"
        @completed="handleOrderCompleted"
        @file-uploaded="handleNewFileUploaded"
        @kg-waste-collected-added="handKgWasteCollectedAdded"
      />
      <orders-inbox
        :is-loading="isLoading"
        :active-order-category="activeOrderCategory"
        :total-orders-in-category="totalOrdersInCategory"
        :orders="orders"
        :can-fetch-more-items="nextPage != -1"
        :is-fetching-items="isFetchingItems"
        @fetch-more-requested="handleFetchMoreItems"
        @category-clicked="handleCategoryChanged"
        @show-order="handleShowOrderClicked"
        @accepted="handleOrderAccept"
        @completed="handleOrderCompleted"
      />
    </div>
  </page-loader>
</template>

<script>
import authHelper from "@/auth";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import SupplierContactsOrdersService from "../../services/supplier-contacts-orders.service";
import OrdersInbox from "../../components/OrdersInbox.vue";
import OrderDetailsModal from "../../components/OrderDetailsModal.vue";
export default {
  components: { PageLoader, OrdersInbox, OrderDetailsModal },
  data() {
    return {
      isLoading: true,
      isFetchingItems: false,
      activeOrderCategory: "INBOX",
      updating: false,
      fetching: true,
      orders: [],
      order: null,
      currentPage: 1,
      nextPage: -1,
      totalOrdersInCategory: 0,
      ordersPageSize: 100,
    };
  },
  async mounted() {
    if (authHelper.isAuthorized() && authHelper.getRole() == "Supplier") {
      this.$router.push({
        name: "suppliers.orders.show",
        params: { id: this.$route.params.id },
      });
      return;
    }

    try {
      await this.handleCategoryChanged("INBOX");
      // We need to fetch order info
      if (this.$route.name == "supplier-contacts.orders.show") {
        const service = new SupplierContactsOrdersService(
          this.$route.params.contactId
        );
        var orderResult = await service.getOderById(this.$route.params.id);
        this.order = orderResult.data.data;
      }
      this.fetching = false;
    } catch {
      alert("An unknown error happend!");
    }
  },
  methods: {
    handleOrderDetailsModalHidden() {
      this.order = null;
      this.$router.push({
        name: "supplier-contacts.orders.list",
        params: { contactId: this.$route.params.contactId },
      });
    },
    async fetcData() {
      const service = new SupplierContactsOrdersService(
        this.$route.params.contactId
      );
      var orderStatus = null;
      switch (this.activeOrderCategory) {
        case "INBOX":
          orderStatus = "Created";
          break;
        case "CONFIRMED":
          orderStatus = "Accepted";
          break;
        case "CLOSED":
          orderStatus = "Collected";
          break;
      }
      var ordersPaged = (
        await service.getPagedOrders(
          this.currentPage,
          this.ordersPageSize,
          orderStatus
        )
      ).data.data;

      this.orders = [...this.orders, ...ordersPaged.items];
      this.totalOrdersInCategory = ordersPaged.paging.totalItems;
      this.nextPage = ordersPaged.paging.nextPage;
    },
    async handleCategoryChanged(newCategory) {
      try {
        this.isLoading = true;
        this.orders = [];
        this.currentPage = 1;
        this.nextPage = -1;
        this.totalOrdersInCategory = 0;
        this.activeOrderCategory = newCategory;
        await this.fetcData();
      } catch {
        alert(this.T("Web.Generic.Errors.Unknown", "Unknown error"));
      } finally {
        this.isLoading = false;
      }
    },
    async handleFetchMoreItems() {
      try {
        this.isFetchingItems = true;
        this.currentPage = this.nextPage;
        this.nextPage = -1;
        await this.fetcData();
      } catch {
        alert(this.T("Web.Generic.Errors.Unknown", "Unknown error"));
      } finally {
        this.isFetchingItems = false;
      }
    },
    async handleShowOrderClicked(order) {
      const service = new SupplierContactsOrdersService(
        this.$route.params.contactId
      );
      var orderResult = await service.getOderById(order.id);
      this.order = orderResult.data.data;
      this.$router.push({
        name: "supplier-contacts.orders.show",
        params: { contactId: this.$route.params.contactId, id: order.id },
      });
    },
    async handleOrderAccept(order) {
      const service = new SupplierContactsOrdersService(
        this.$route.params.contactId
      );
      await service.acceptOrderById(order.id);
      var orderToUpdate = this.orders.find((o) => o.id == order.id);
      orderToUpdate.status = "Accepted";
      this.handleOrderDetailsModalHidden();
      this.$notify({
        group: "default",
        title: this.T("Web.Generic.Ordering.Suppliers.OrderAccepted"),
        type: "success",
      });
    },
    async handleOrderCompleted(order) {
      const service = new SupplierContactsOrdersService(
        this.$route.params.contactId
      );
      await service.completeOrderById(order.id);
      var orderToUpdate = this.orders.find((o) => o.id == order.id);
      orderToUpdate.status = "Collected";
      this.handleOrderDetailsModalHidden();
      this.$notify({
        group: "default",
        title: this.T("Web.Generic.Ordering.MarkAsCollected"),
        type: "success",
      });
    },
    async handleNewFileUploaded(order) {
      this.updating = true;
      const service = new SupplierContactsOrdersService(
        this.$route.params.contactId
      );
      var orderResult = await service.getOderById(order.id);
      this.order.files = orderResult.data.data.files;
      this.updating = false;
    },
    async handKgWasteCollectedAdded(data) {
      try {
        this.updating = true;
        const service = new SupplierContactsOrdersService(
          this.$route.params.contactId
        );
        await service.addKgWasteCollected(data.id, data.kgWasteCollected);
        this.updating = false;
        this.$notify({
          group: "default",
          title: this.T("Web.Generic.Ordering.KgWasteCollected"),
          type: "success",
        });
      } catch {
        alert(this.T("Web.Generic.Errors.Unknown"));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
}
</style>